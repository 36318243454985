body{
  margin: 0;
}

h2{
  font-size: 20px;
}

h3 {
  font-size: 14px;
}

.bg-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -999;
}

.header{
  height: 50px;
  background-color: #9f215b;
}

.header-text{
  color: #fff;
  text-align: center;
  padding-top: 18px;
  text-transform: uppercase;
  font-family: Montserrat;
  text-decoration: none;
}

.survey-container {
  position: absolute;
  width: 100%;
  height: calc(100% - 50px);
}

.survey-link{
  border-radius: 5px;
  margin-top: 20px;
  padding: 5px;
  cursor: pointer;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  font-style: italic;
  font-family: Montserrat;
  text-align: center;
  background: #45A535;
}

.survey-link-text{
  color: #fff;
  padding: 20px 0 !important;
  text-decoration: none;
}

.option-window{
  display: inline-block;
  font-family: montserrat;
  color: #fff;
  background: rgba(0,0,0,0.8);
  margin: 50px;
  padding: 0 20px 20px 20px;
  width: 145px;
  border-radius: 10px;
}

.float-right{
  float: right;
}

.align-right{
  text-align: right;
}

.nickname {
    font-size: 26px;
    margin: 0;
    text-align: right;
    font-family: montserrat;
    font-weight: 600;
    text-transform: uppercase;
    font-style: italic;
}

.title {
    font-size: 14px;
    margin: 0;
    font-family: montserrat;
    font-weight: 300;
    color: #777;
    text-transform: uppercase;
}

.fullname {
  font-weight: 200 !important;
  margin: 0;
  text-align: right;
  font-size: 19px;
  word-break: break-word;
}

.detail {
  margin: 0;
  text-align: right;
  font-size: 25px;
  font-weight: 600;
  font-style: italic;
  font-family: Montserrat;
  padding-bottom: 10px;
}

.characterswatch {
  border: 1px #aaa solid;
  border-radius: 2px;
  margin: 2px 0;
  padding:  5px;
  cursor: pointer;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  font-style: italic;
  font-family: Montserrat;
  text-align: center;
}

.characterswatch:hover {
  background-color: #555;
}

.characterswatch-active {
  background-color: #fff !important;
  color: #333;
}

.colorswatch {
  height: 40px;
  width: 40px;
  margin: 5px 5.3px 0 0;
  float: left;
  border-radius: 50px;
  border: 1.5px #fff solid;
  cursor: pointer;
  background-image: url(./images/3d-effect-5.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.colorswatch:hover{
  border: 1.5px #fff solid;
  -webkit-filter: brightness(120%);
}

.colorswatch-active {
  border: 1.5px #fff solid;
  background-image: url(./images/3d-effect-selected.png);
}

.sizeswatch {
  width: 40px;
  margin: 2px;
  padding: 4px 0;
  float: left;
  border: 2px #fff solid;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-weight: lighter;
}

.sizeswatch:hover{
  background-color: #555;
}

.sizeswatch-active {
  background-color: #fff !important;
  color: #333;
  font-weight: bold;
}

.detail-block{
  clear: both;
  padding-top: 20px;
  text-align: center;
}

.clear{
  clear: both;
}

@media (max-width: 425px) {
  .fullname {
    font-size: 13px !important;
  }

  .survey-link{
    right: -10px;
  }

  .option-window{
    transform: scale(0.7);
    margin: 0;
    max-width: 97px;
  }

  .right-dashboard{
    position: absolute;
    top: 20px;
    right: -10px;
    transform-origin: top;
  }

  .left-dashboard{
    position: absolute;
    top: 20px;
    left: -10px;
    transform-origin: top;
  }
}
